import { useNavigate, useParams } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { ErrorComponent } from "components/Layout/Error";
import { useCreatorAuth } from "components/hooks/useCreatorAuth";

import { Overview } from "./Overview";
import { useCreatorDashboardQuery } from "./generated";

export const OverviewContainer: React.FC = () => {
  const { userHash } = useParams<{ userHash: string }>();
  const navigate = useNavigate();
  const { campaignStub } = useParams<{ campaignStub: string }>();
  const { isLoggedIn } = useCreatorAuth({ campaignStub, userHash });

  const { data, loading } = useCreatorDashboardQuery({
    skip: !isLoggedIn
  });

  const user = data?.creatorDashboard;

  if (isLoggedIn === false) {
    return <ErrorComponent message="Something happened, try again later..." />;
  }

  if (!isLoggedIn || loading) {
    return <Loader />;
  }

  if (user && (!user.hasFbAccessToken || !user.profileMatchesAccessToken)) {
    navigate(`/auth/${userHash}`);
    return null;
  }

  if (!user || !userHash) return null;

  return <Overview user={user} />;
};
