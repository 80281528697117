import { type FC, Suspense, useCallback } from "react";

import { useReportFeErrorMutation } from "@relatable/gql/generated";
import { ErrorBoundary } from "@relatable/ui/ErrorBoundary";
import { Loader } from "@relatable/ui/Loader";
import { Outlet } from "react-router-dom";

export const Layout: FC = () => {
  const [mutate] = useReportFeErrorMutation();

  const errorHandler = useCallback(
    (error: string) => {
      mutate({
        variables: { url: window.location.href, message: error }
      });
    },
    [mutate]
  );

  return (
    <ErrorBoundary onError={errorHandler}>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  );
};
