import { useContext, useEffect, useState } from "react";

import { AuthContext } from "components/AuthContext";

const doLogin = async ({
  campaignUserHash,
  userHash,
  campaignStub
}: {
  campaignUserHash?: string;
  userHash?: string;
  campaignStub?: string;
}) => {
  try {
    const url = new URL(`${process.env.GRAPH_URL}/auth/creator/login`);
    if (campaignUserHash) {
      url.searchParams.set("encodedCampaignUserId", campaignUserHash);
    } else if (userHash) {
      url.searchParams.set("encodedUserId", userHash);
    }

    url.searchParams.set("campaignStub", campaignStub ?? "");

    const results = await fetch(url, {
      method: "POST",
      credentials: "include"
    }).then(x => x.json());

    if (!results.token) return false;
    localStorage.setItem("authToken", results.token);
    return true;
  } catch (err) {
    console.error("Login failed", err);
    return false;
  }
};

let lastLoginCallTime = 0;
let loginPromise: null | Promise<boolean> = null;
const doLoginThrottled = async (params: Parameters<typeof doLogin>[0]) => {
  if (Date.now() - lastLoginCallTime < 1000) return loginPromise;
  lastLoginCallTime = Date.now();
  loginPromise = doLogin(params);
  return loginPromise;
};

export const useCreatorAuth = ({
  campaignUserHash,
  userHash,
  campaignStub
}: {
  campaignUserHash?: string;
  userHash?: string;
  campaignStub?: string;
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(
    lastLoginCallTime === 0 ? null : true
  );

  const { setToken } = useContext(AuthContext);

  useEffect(() => {
    const action = () => {
      if (!campaignUserHash && !userHash) return;

      if (campaignUserHash || userHash) {
        doLoginThrottled({ campaignUserHash, userHash, campaignStub }).then(isSuccess => {
          const token = localStorage.getItem("authToken");
          if (!token) throw new Error("Cannot find token");
          setToken(token);
          setIsLoggedIn(isSuccess);
        });
      }
    };

    // initial login
    action();
  }, [campaignUserHash, userHash, campaignStub, setToken]);

  return { isLoggedIn };
};
