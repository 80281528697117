import { type FC, type ReactNode, useEffect } from "react";

import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { Paper } from "@relatable/ui/Paper";
import { useLocation, useNavigate } from "react-router-dom";

import { useCreatorAuth } from "components/hooks/useCreatorAuth";

import { Header } from "./Header";
import { useEncodedUser } from "./useEncodedUser";

export const SignLayout: FC<{ children?: ReactNode }> = ({ children }) => {
  const { user, hash, campaignStub } = useEncodedUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useCreatorAuth({ campaignStub, userHash: hash });

  useEffect(() => {
    if (location.pathname.endsWith(hash)) navigate(`${location.pathname}/start`, { replace: true });
  }, [hash, location.pathname, navigate]);

  if (isLoggedIn === false) throw new Error("Something happened, try again later...");

  if (!isLoggedIn) return <Loader />;

  return (
    <>
      <Header data={user} />
      <Paper
        style={{
          margin: 20,
          padding: 20,
          textAlign: "center",
          background: palette.gray[10],
          height: "100%"
        }}
        variant="outlined"
      >
        {user ? children : <Loader />}
      </Paper>
    </>
  );
};
