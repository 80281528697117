import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { useCreatorAuth } from "components/hooks/useCreatorAuth";

import { useUserByEncodedIdQuery } from "./generated";

export const useEncodedUser = () => {
  const { campaignStub, hash } = useParams<{
    hash: string;
    campaignStub: string;
  }>();
  if (!hash || !campaignStub) throw Error("Sth went wrong, hash or campaignStub is missing");

  const { isLoggedIn } = useCreatorAuth({ campaignStub, userHash: hash });

  const { data, stopPolling, error } = useUserByEncodedIdQuery({
    variables: { encodedId: hash, campaignStub },
    pollInterval: 2000,
    skip: !isLoggedIn
  });

  useEffect(() => {
    if (error) stopPolling();
  }, [error, stopPolling]);

  return { user: data?.agreementDataByEncodedId, campaignStub, hash };
};
