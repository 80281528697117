import type { FC } from "react";

import styled from "@emotion/styled";
import {
  faArrowToLeft,
  faArrowToRight,
  faCheck,
  faCommentAlt,
  faImage,
  faSave,
  faTimes
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CONTENT_APPROVAL_STATE } from "@relatable/helpers/constants";
import { palette } from "@relatable/ui/Palette";

export const ContentItemHeader: FC<{
  mediaStatus: CONTENT_APPROVAL_STATE;
  onMoveMedia?: (direction: "right" | "left") => void;
  onDownload: () => void;
  index: number;
  numSlides: number;
  editable: boolean;
  numComments: number;
}> = ({ mediaStatus, onMoveMedia, onDownload, index, numSlides, editable, numComments }) => {
  return (
    <Root $status={mediaStatus}>
      <div className="left">
        <FontAwesomeIcon icon={faImage} style={{ marginRight: 6 }} />
        {index + 1}/{numSlides}
        {numComments > 0 && (
          <>
            <FontAwesomeIcon icon={faCommentAlt} style={{ margin: "0 6px 0 12px" }} />
            {numComments}
          </>
        )}
      </div>
      <div className="right">
        <ActionButton type="button" onClick={onDownload} title="Download">
          <FontAwesomeIcon icon={faSave} size="lg" />
        </ActionButton>
        {editable && numSlides > 1 && (
          <>
            {index !== 0 && (
              <ActionButton type="button" onClick={() => onMoveMedia?.("left")} title="Move left">
                <FontAwesomeIcon icon={faArrowToLeft} size="lg" />
              </ActionButton>
            )}
            {index !== numSlides - 1 && (
              <ActionButton type="button" onClick={() => onMoveMedia?.("right")} title="Move right">
                <FontAwesomeIcon size="lg" icon={faArrowToRight} />
              </ActionButton>
            )}
          </>
        )}
        {mediaStatus === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING && (
          <FontAwesomeIcon icon={faCheck} size="lg" style={{ marginLeft: 8 }} />
        )}
        {mediaStatus === CONTENT_APPROVAL_STATE.MANAGER_REJECTED && !editable && (
          <FontAwesomeIcon className="hover" icon={faTimes} size="lg" />
        )}
      </div>
    </Root>
  );
};

const Root = styled.div<{ $status: string }>`
  width: 100%;
  height: 40px;
  border: 1px solid ${palette.gray[20]};
  border-bottom: 0px;
  padding: 0 10px 0 18px;
  ${({ $status }) => {
    let color = palette.gray.white as string;
    let background: string;
    if ([CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING].includes($status as CONTENT_APPROVAL_STATE)) {
      background = palette.primary.green;
    } else if ($status === CONTENT_APPROVAL_STATE.MANAGER_REJECTED) {
      background = palette.primary.gold;
    } else {
      color = palette.gray.black;
      background = palette.gray.white;
    }

    return { color, background };
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hover {
    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }
  }
`;

const ActionButton = styled.button`
  border: 0;
  background: inherit;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;
  padding: 10px 6px;
  color: inherit;
  &:last-child {
    margin-right: 0;
  }
`;
