import { type FC, useMemo, useState } from "react";

import { GqlProvider, getGqlClient } from "@relatable/gql";
import { UIProvider } from "@relatable/ui/UIProvider";
import { RouterProvider } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { AuthContext } from "components/AuthContext";

import { router } from "./routes";

export const Root: FC = () => {
  const [authToken, setAuthToken] = useState<string | null>(null);

  const gqlClient = useMemo(
    () =>
      getGqlClient({
        headers: {
          "origin-type": "creator",
          Authorization: `Bearer ${authToken}`
        }
      }),
    [authToken]
  );

  const authContext = useMemo(() => ({ setToken: setAuthToken }), []);

  return (
    <AuthContext.Provider value={authContext}>
      <GqlProvider client={gqlClient}>
        <UIProvider>
          <RouterProvider router={router} fallbackElement={<Loader />} />
        </UIProvider>
      </GqlProvider>
    </AuthContext.Provider>
  );
};
