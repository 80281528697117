import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import { Root } from "./Root";

document.addEventListener("DOMContentLoaded", () => {
  console.log(`Creator app [${process.env.COMMIT_HASH}] ${process.env.BUILD_DATE}`);

  const container = document.getElementById("root");
  if (!container) throw new Error("Root element not found");
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <Root />
    </StrictMode>
  );
});
