import styled from "@emotion/styled";
import { getContentAggregateState } from "@relatable/helpers/approvalLogs";
import { CONTENT_APPROVAL_STATE, CONTENT_TYPES } from "@relatable/helpers/constants";
import { MMM_DD, timeFromNow } from "@relatable/helpers/date";
import { palette } from "@relatable/ui/Palette";

import {
  getContentUpdatedAtTimestamps,
  getNumberOfCommentsForContent
} from "modules/approval/components/ContentApproval/utils";
import { PLATFORMS } from "modules/approval/lib/constants";

import type { ContentApprovalListQuery } from "../generated";
import { ContentStatus } from "./ContentStatus";
import { Thumbnail } from "./Thumbnail";

type ContentItem =
  ContentApprovalListQuery["campaign"][number]["campaign_content_settings"][number];

const shouldShowAsApproved = (contentApprovalState: string | null | undefined): boolean =>
  contentApprovalState === CONTENT_APPROVAL_STATE.READY_FOR_PUBLISHING;

export const ContentCard: React.FC<{
  content: ContentItem;
  editLocation: string;
  platform: string | null | undefined;
  index: number;
  indexTotal: number;
}> = ({
  content: { publish_date_min, publish_date_max, type, campaign_preapproval_contents },
  editLocation,
  platform,
  index,
  indexTotal
}) => {
  const { medias = [], caption } = campaign_preapproval_contents[0] ?? {};

  const [{ thumbnail = "" }] =
    medias.length > 0
      ? [...medias].sort((a, b) => {
          if (shouldShowAsApproved(a.media_state) && !shouldShowAsApproved(b.media_state)) {
            return 1;
          }
          if (!shouldShowAsApproved(a.media_state) && shouldShowAsApproved(b.media_state)) {
            return -1;
          }
          return (a.index ?? 0) - (b.index ?? 0);
        })
      : [{}];

  const numComments = getNumberOfCommentsForContent(campaign_preapproval_contents[0]);
  const contentUpdatedAtTimestamps = getContentUpdatedAtTimestamps(
    campaign_preapproval_contents[0]
  );
  const lastUpdated =
    contentUpdatedAtTimestamps.length > 0
      ? Math.max(...contentUpdatedAtTimestamps.map(i => new Date(i).getTime()))
      : null;

  const contentState = (() => {
    const itemStates: (string | null | undefined)[] = [];
    if (caption) itemStates.push(caption.caption_state);
    if (medias.length > 0) {
      itemStates.push(...medias.map(m => m.media_state));
    }

    return getContentAggregateState(itemStates);
  })();

  const titleParts = [
    platform === PLATFORMS.YOUTUBE
      ? "Video"
      : Object.values(CONTENT_TYPES).find(ct => ct.value === type)?.label ?? "?"
  ];

  if (indexTotal > 1) {
    titleParts.push(`${index + 1}`);
  }

  if (publish_date_min && publish_date_max) {
    if (publish_date_min === publish_date_max) {
      titleParts.push(`(${MMM_DD(publish_date_min)})`);
    } else {
      titleParts.push(`(${MMM_DD(publish_date_min)} - ${MMM_DD(publish_date_max)})`);
    }
  }

  return (
    <Root $state={contentState}>
      <ContentStatus contentState={contentState} numComments={numComments} />
      <Title>
        <strong>{titleParts.join(" ")}</strong>
      </Title>

      <PreviewContent>
        <Thumbnail
          source={thumbnail ?? ""}
          url={editLocation}
          height={platform === PLATFORMS.YOUTUBE ? 220 : 280}
          width={400}
          usePercentage
        />
      </PreviewContent>

      {thumbnail && lastUpdated ? (
        <EditStatus>Last edited {timeFromNow(lastUpdated)}</EditStatus>
      ) : null}
    </Root>
  );
};

const Root = styled.div<{ $state: string }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  transform: perspective(1px) translateZ(0);
  transition: all 0.25s;
  transition-property: transform;
  margin: 24px 0px;
  background-color: #ffffff;
  border: 1px solid ${palette.gray[30]};
  border-radius: 4px;
`;

const Title = styled.span`
  padding: 8px 12px;
  border-bottom: 1px solid ${palette.gray[30]};
`;

const PreviewContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  color: #ffffff;
`;

const EditStatus = styled.span`
  font-size: 12px;
  padding: 8px 12px;
  color: ${palette.gray[60]};
  background-color: ${palette.gray[20]};
  border-top: 1px solid ${palette.gray[30]};
  border-radius: 0px 0px 3px 3px;
`;
